body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root {
  width: 100%;
  height: 100%;
  font-size: 16px;
}

#root {
  display: flex;
  flex-direction: column;
}

h1, h2 {
  margin: 0 !important;
}

.euiButton__content {
  padding: 0 !important;
}

.euiFormControlLayout, .euiFormControlLayout--compressed {
  height: auto !important;
}

ul {
  list-style-type: none;
  padding: 0;
}
